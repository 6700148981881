@import 'vars';
@import '@angular/cdk/overlay-prebuilt.css';

:root {
  --header-height: calc(env(safe-area-inset-top) + #{$default-header-height});
}

$sm: 640px;
$lg: 1024px;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  overscroll-behavior-y: none;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  @media (max-width: $md) {
    width: 1px;
  }
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.modal_panel {
  min-width: 100%;
  margin-left: unset;
  margin-right: unset;
  @media (min-width: $sm) {
    min-width: unset;
  }
}

.fullscreen_modal_panel {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.modal_backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.cdk-global-overlay-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cdk-dialog-container {
  background: white;
  border-radius: 4px;
  padding: 24px;
}

// Used by [padspinDialogTitle] directive
$padspin_dialog_title_height: 30px;
$padspin_dialog_actions_height: 52px;
.padspin_dialog_title {
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 0 1px;
}
// Used by the [padspinDialogContent] directive
.padspin_dialog_content {
  display: block;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  max-height: 65vh;
  @media (min-height: 850px) {
    max-height: calc(
      85vh - #{$padspin_dialog_actions_height} - #{$padspin_dialog_title_height}
    );
  }
}
// Used by the [padspinDialogActions] directive
.padspin_dialog_actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-height: $padspin_dialog_actions_height;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
  gap: 10px;
}

// Start Material replacement styles
$custom-typography-config: (
  'font-family': unquote('system-ui, sans-serif'),
  'font-size': 14px,
  'font-weight': 400,
  'line-height': 20px,
  'h1': (
    'font-size': 24px,
    'font-weight': 400,
    'line-height': 32px,
    'letter-spacing': normal,
    'margin': 0 0 16px,
    'font-family': unquote('system-ui, sans-serif'),
  ),
  'h2': (
    'font-size': 20px,
    'font-weight': 500,
    'line-height': 32px,
    'letter-spacing': normal,
    'margin': 0 0 16px,
    'font-family': unquote('system-ui, sans-serif'),
  ),
  'h3': (
    'font-size': 16px,
    'font-weight': 400,
    'line-height': 28px,
    'letter-spacing': normal,
    'margin': 0 0 16px,
    'font-family': unquote('system-ui, sans-serif'),
  ),
  'h4': (
    'font-size': 15px,
    'font-weight': 400,
    'line-height': 24px,
    'letter-spacing': normal,
    'margin': 0 0 16px,
    'font-family': unquote('system-ui, sans-serif'),
  ),
  'h5': (
    'font-size': calc(14px * 0.83),
    'font-weight': 400,
    'line-height': 20px,
    'letter-spacing': normal,
    'margin': 0 0 12px,
    'font-family': unquote('system-ui, sans-serif'),
  ),
  'h6': (
    'font-size': calc(14px * 0.67),
    'font-weight': 400,
    'line-height': 20px,
    'letter-spacing': normal,
    'margin': 0 0 12px,
    'font-family': unquote('system-ui, sans-serif'),
  ),
  'subtitle1': (
    'font-size': 16px,
    'font-weight': 400,
    'line-height': 28px,
    'letter-spacing': 0.15px,
  ),
  'subtitle2': (
    'font-size': 14px,
    'font-weight': 500,
    'line-height': 24px,
    'letter-spacing': 0.1px,
  ),
  'body1': (
    'font-size': 16px,
    'font-weight': 400,
    'line-height': 24px,
    'letter-spacing': 0.5px,
    'margin': 0 0 12px,
  ),
);

body {
  font-family: map-get($custom-typography-config, 'font-family');
  font-size: map-get($custom-typography-config, 'font-size');
  font-weight: map-get($custom-typography-config, 'font-weight');
  line-height: map-get($custom-typography-config, 'line-height');
}

h1 {
  font-size: map-get(map-get($custom-typography-config, 'h1'), 'font-size');
  font-weight: map-get(map-get($custom-typography-config, 'h1'), 'font-weight');
  line-height: map-get(map-get($custom-typography-config, 'h1'), 'line-height');
  font-family: map-get(map-get($custom-typography-config, 'h1'), 'font-family');
  letter-spacing: map-get(
    map-get($custom-typography-config, 'h1'),
    'letter-spacing'
  );
  margin: map-get(map-get($custom-typography-config, 'h1'), 'margin');
}

h2 {
  font-size: map-get(map-get($custom-typography-config, 'h2'), 'font-size');
  font-weight: map-get(map-get($custom-typography-config, 'h2'), 'font-weight');
  line-height: map-get(map-get($custom-typography-config, 'h2'), 'line-height');
  font-family: map-get(map-get($custom-typography-config, 'h2'), 'font-family');
  letter-spacing: map-get(
    map-get($custom-typography-config, 'h2'),
    'letter-spacing'
  );
  margin: map-get(map-get($custom-typography-config, 'h2'), 'margin');
}

h3 {
  font-size: map-get(map-get($custom-typography-config, 'h3'), 'font-size');
  font-weight: map-get(map-get($custom-typography-config, 'h3'), 'font-weight');
  line-height: map-get(map-get($custom-typography-config, 'h3'), 'line-height');
  font-family: map-get(map-get($custom-typography-config, 'h3'), 'font-family');
  letter-spacing: map-get(
    map-get($custom-typography-config, 'h3'),
    'letter-spacing'
  );
  margin: map-get(map-get($custom-typography-config, 'h3'), 'margin');
}

h4 {
  font-size: map-get(map-get($custom-typography-config, 'h4'), 'font-size');
  font-weight: map-get(map-get($custom-typography-config, 'h4'), 'font-weight');
  line-height: map-get(map-get($custom-typography-config, 'h4'), 'line-height');
  letter-spacing: map-get(
    map-get($custom-typography-config, 'h4'),
    'letter-spacing'
  );
}

h5 {
  font-size: map-get(map-get($custom-typography-config, 'h5'), 'font-size');
  font-weight: map-get(map-get($custom-typography-config, 'h5'), 'font-weight');
  line-height: map-get(map-get($custom-typography-config, 'h5'), 'line-height');
  font-family: map-get(map-get($custom-typography-config, 'h5'), 'font-family');
  letter-spacing: map-get(
    map-get($custom-typography-config, 'h5'),
    'letter-spacing'
  );
  margin: map-get(map-get($custom-typography-config, 'h5'), 'margin');
}

h6 {
  font-size: map-get(map-get($custom-typography-config, 'h6'), 'font-size');
  font-weight: map-get(map-get($custom-typography-config, 'h6'), 'font-weight');
  line-height: map-get(map-get($custom-typography-config, 'h6'), 'line-height');
  font-family: map-get(map-get($custom-typography-config, 'h6'), 'font-family');
  letter-spacing: map-get(
    map-get($custom-typography-config, 'h6'),
    'letter-spacing'
  );
  margin: map-get(map-get($custom-typography-config, 'h6'), 'margin');
}

p {
  font-size: map-get(map-get($custom-typography-config, 'body1'), 'font-size');
  font-weight: map-get(
    map-get($custom-typography-config, 'body1'),
    'font-weight'
  );
  line-height: map-get(
    map-get($custom-typography-config, 'body1'),
    'line-height'
  );
  font-family: map-get(
    map-get($custom-typography-config, 'body1'),
    'font-family'
  );
  margin: map-get(map-get($custom-typography-config, 'body1'), 'margin');
}
// End Material replacement styles
